import * as React from "react";
import { useMemo } from "react";
import { RadioButton } from "./RadioButton";
import { Fieldset, Legend, Wrapper } from "./RadioButtonGroup.styles";
import { IRadioButtonOption } from "./ReadioButtonGroup.types";

interface IProps {
    readonly label: string;
    readonly options: Array<IRadioButtonOption>;
    readonly hasFullWidth?: boolean;
    readonly onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    readonly selectedKey: string | null;
}

export const RadioButtonGroup: React.FC<IProps> = ({ label, options, selectedKey, onChange }) => {
    const renderedOptions = useMemo(() => {
        return options.map(({ label, key, disabled }: IRadioButtonOption) => {
            const optionId = `radio-option-${key}`;
            const isChecked = key === selectedKey;

            return (
                <RadioButton
                    value={key}
                    label={label}
                    key={key}
                    id={optionId}
                    name={key}
                    disabled={disabled}
                    onChange={onChange}
                    checked={isChecked}
                />
            );
        });
    }, [options, selectedKey]);
    return (
        <Fieldset>
            <Legend>{label}</Legend>
            <Wrapper>{renderedOptions}</Wrapper>
        </Fieldset>
    );
};
