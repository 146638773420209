import styled from "styled-components";
import { Dimensions } from "../../../../../layout/GlobalStyles/Dimensions";
import { Color } from "../../../../../layout/GlobalStyles/Color";
import { Viewport } from "../../../../../layout/GlobalStyles/Viewport";

export const List = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: ${Dimensions.GridUnit * 2}px;
    gap: ${Dimensions.GridUnit * 2}px;
    @media (max-width: ${Viewport.Medium}px) {
        flex-direction: column;
    }
`;

export const ImageRoot = styled.li`
    width: 250px;
    min-height: 300px;
    background: ${Color.Neutral10};
    border: 3px solid ${Color.Neutral10};
    border-radius: ${Dimensions.GridUnit}px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: ${Dimensions.GridUnit}px;
    box-sizing: border-box;
    @media (max-width: ${Viewport.Medium}px) {
        width: 100%;
        margin-bottom: ${Dimensions.GridUnit * 2}px;
        height: 200px;
        overflow: hidden;
    }
`;
