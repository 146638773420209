import styled from "styled-components";
import { Dimensions } from "../../../../../../layout/GlobalStyles/Dimensions";
import { Viewport } from "../../../../../../layout/GlobalStyles/Viewport";

export const StyledImage = styled.img`
    max-width: 200px;
    max-height: 200px;
    object-fit: contain;

    margin: 0 auto;

    @media (max-width: ${Viewport.Medium}px) {
        width: 100%;
        height: 200px;
        overflow: hidden;
    }
`;

export const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: ${Dimensions.GridUnit * 2}px;
    flex-direction: column;
`;
