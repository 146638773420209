import { JobStyle, TelemetryEvent } from "@headbot/library";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useContext } from "react";
import { AlertsContext, AlertType, IAlert } from "../../../components/molecules/Alerts/AlertsContext";
import { getAxiosRequestConfig } from "../../../functions/getAxiosRequestConfig";
import { Constants } from "../../../services/Constants/Constants";
import { telemetry } from "../../../services/Telemetry/Telemetry";
import { QueryCacheKey } from "../QueryCacheKey";

interface IResponse {
    readonly guestJobId: string;
}

interface IOptions {
    readonly gender: string;
    readonly style: JobStyle;
}

const getMutationQuery = () => {
    const postRequest = async (options: IOptions): Promise<IResponse> => {
        const { gender, style } = options;
        const config = getAxiosRequestConfig(null);
        const request = axios.post(
            `${Constants.ApiRoot}/guestJob`,
            { gender, style, initId: telemetry.InitId, clientId: telemetry.ClientId },
            config
        );

        const { data } = await request;
        return data;
    };
    return postRequest;
};

export const usePostGuestJob = (onSuccess: (response: IResponse) => void) => {
    const { pushAlert } = useContext(AlertsContext);
    const queryClient = useQueryClient();

    const mutation = useMutation(getMutationQuery(), {
        onMutate: () => {
            telemetry.Log(TelemetryEvent.CreateGuestJobError, null);
        },
        onSuccess: (data) => {
            telemetry.Log(TelemetryEvent.CreateGuestJobSuccess, null);
            queryClient.invalidateQueries([QueryCacheKey.GuestJob]);
            onSuccess(data);
        },
        onError: (error, _variables, _context) => {
            const e = error as AxiosError<any>;
            const submitError = JSON.stringify(e.response?.data?.error);
            const alert: IAlert = {
                type: AlertType.Error,
                message: submitError,
                timeout: Constants.AlertModalTimeoutMs,
            };
            pushAlert?.(alert);
            telemetry.Log(TelemetryEvent.CreateGuestJobInit, submitError);
        },
    });
    return { mutation };
};
