import { IconUpload } from "@tabler/icons-react";
import * as React from "react";
import { useCallback } from "react";
import { Color } from "../../layout/GlobalStyles/Color";
import { FormField, IconWrapper, UploaderContainer } from "./FileUploader.style";

export interface IFileInput {
    readonly data: File;
    readonly size: number;
    readonly lastModified: number;
    readonly name: string;
    readonly type: string;
}

interface IProps {
    readonly label: string;
    readonly onFilesLoaded: (value: Array<IFileInput>) => void;
    readonly multiple?: boolean;
    readonly accept?: string;
}

export const FileUploader: React.FC<React.PropsWithChildren<IProps>> = ({ label, multiple = false, onFilesLoaded, accept }) => {
    const onChange = useCallback(
        async (e: React.ChangeEvent<HTMLInputElement>) => {
            const uploadedFiles = e.target.files;
            const base64images: Array<IFileInput> = [];
            if (uploadedFiles === null) {
                return base64images;
            }
            for (let i = 0; i < uploadedFiles.length; i++) {
                const rawFile = uploadedFiles.item(i);
                if (rawFile === null) {
                    continue;
                }
                if (rawFile === null) {
                    continue;
                }
                const { size, lastModified, name, type } = rawFile;
                const input: IFileInput = {
                    data: rawFile,
                    size,
                    lastModified,
                    name,
                    type,
                };
                base64images.push(input);
            }
            onFilesLoaded(base64images);
        },
        [onFilesLoaded]
    );

    return (
        <UploaderContainer>
            <IconUpload size={36} strokeWidth={2} color={Color.White100} />
            <IconWrapper>{label}</IconWrapper>
            <FormField type="file" onChange={onChange} multiple={multiple} accept={accept} />
        </UploaderContainer>
    );
};
