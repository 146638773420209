import { JobStyle } from "@headbot/library";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { HeadbotRoute } from "../../../services/Constants/HeadbotRoute";
import { PaypalProvider } from "../../layout/PaypalProvider/PaypalProvider";
import { CreateJob } from "../../molecules/CreateJob/CreateJob";
import { ScrollablePageTemplate } from "../../templates/ScrollablePageTemplate/ScrollablePageTemplate";

interface IProps {
    readonly jobStyle: JobStyle;
}

export const CreateJobPage: React.FC<React.PropsWithChildren<IProps>> = ({ jobStyle }) => {
    const [t] = useTranslation();
    const description = t("createJobPage.description");
    const title = t("createJobPage.title");
    const canonical = jobStyle === JobStyle.Buff ? HeadbotRoute.CreateBuff : HeadbotRoute.CreateNaruto;
    return (
        <PaypalProvider>
            <ScrollablePageTemplate title={title} description={description} canonical={canonical}>
                <CreateJob jobStyle={jobStyle} />
            </ScrollablePageTemplate>
        </PaypalProvider>
    );
};
