import * as React from "react";
import { useTranslation } from "react-i18next";
import { FileUploader, IFileInput } from "../../../../atoms/FileUploader/FileUploader";
import { FileInputPreview } from "./FileInputPreview/FileInputPreview";
import { useCallback } from "react";

interface IProps {
    readonly setImages: (files: Array<Array<IFileInput>>) => void;
    readonly images: Array<Array<IFileInput>>;
}
export const SingleSelector: React.FC<IProps> = ({ setImages, images }) => {
    const [t] = useTranslation();

    const onFilesLoaded = useCallback(
        (files: Array<IFileInput>) => {
            console.log(files);
            setImages([files]);
        },
        [setImages, images]
    );

    return (
        <>
            <FileUploader
                label={t("createJobPage.stage2.uploadFileLabel")}
                onFilesLoaded={onFilesLoaded}
                multiple={true}
                accept="image/*"
            />
            <FileInputPreview images={images} setImages={setImages} />
        </>
    );
};
