import { JobStyle, TelemetryEvent } from "@headbot/library";
import * as React from "react";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Constants } from "../../../../../services/Constants/Constants";
import { telemetry } from "../../../../../services/Telemetry/Telemetry";
import { PrimaryButton } from "../../../../atoms/Button/PrimaryButton";
import { SecondaryButton } from "../../../../atoms/Button/SecondaryButton";
import { WhatPhotosNeeded } from "../../../Instructions/WhatPhotosNeeded";
import { CreateJobContext } from "../../CreateJobContext";
import { CenteredH1, CenteredP, Root } from "../Stages.styles";
import { MultiSelector } from "./MultiSelector";
import { SingleSelector } from "./SingleSelector";

export const Stage2: React.FC = () => {
    const [t] = useTranslation();
    const { onBack, onContinue, images, setImages, style } = useContext(CreateJobContext);

    const imageCount = images.reduce((acc, val) => acc + val.length, 0);
    const isContinueDisabled = imageCount < Constants.MinimumRequiredImages;

    useEffect(() => {
        telemetry.Log(TelemetryEvent.CreateJobStage2);
    }, []);

    const shouldShowMultiSelector = style === JobStyle.Couple;

    return (
        <>
            <Root>
                <CenteredP>{t("createJobPage.stepProgress", { currentStep: 2 })}</CenteredP>
                <SecondaryButton onClick={onBack} type="button">
                    {t("createJobPage.back")}
                </SecondaryButton>
                <CenteredH1>{t("createJobPage.stage2.uploadPhotos")}</CenteredH1>
                <WhatPhotosNeeded />
                {shouldShowMultiSelector ? (
                    <MultiSelector setImages={setImages} />
                ) : (
                    <SingleSelector setImages={setImages} images={images} />
                )}
                <PrimaryButton onClick={onContinue} type="button" disabled={isContinueDisabled}>
                    {t("createJobPage.continue")}
                </PrimaryButton>
            </Root>
        </>
    );
};
