import { JobStyle } from "@headbot/library";
import * as React from "react";
import { useContext } from "react";
import { CreateJobContext, CreateJobContextProvider } from "./CreateJobContext";

interface IProps {
    readonly jobStyle: JobStyle;
}

const ActiveStage: React.FC = () => {
    const { activeStage } = useContext(CreateJobContext);
    return activeStage;
};

export const CreateJob: React.FC<IProps> = ({ jobStyle }) => {
    return (
        <CreateJobContextProvider jobStyle={jobStyle}>
            <ActiveStage />
        </CreateJobContextProvider>
    );
};
