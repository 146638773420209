import * as React from "react";
import { FileUploader, IFileInput } from "../../../../atoms/FileUploader/FileUploader";
import { useTranslation } from "react-i18next";

interface IProps {
    readonly setImages: (files: Array<Array<IFileInput>>) => void;
}

export const MultiSelector: React.FC<IProps> = ({ setImages }) => {
    const [t] = useTranslation();
    const onFilesLoaded = (files: Array<IFileInput>) => {
        setImages([files]);
    };

    return (
        <FileUploader label={t("createJobPage.stage2.uploadFileLabel")} onFilesLoaded={onFilesLoaded} multiple={true} accept="image/*" />
    );
};
