import * as React from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../../../../../../atoms/Button/PrimaryButton";
import { Root, StyledImage } from "./SelectedImage.styles";

interface IProps {
    readonly url: string;
    readonly setIndex: number;
    readonly imageIndex: number;
    readonly onImageRemoved: (setIndex: number, imageIndex: number) => void;
}

export const SelectedImage: React.FC<IProps> = ({ url, setIndex, imageIndex, onImageRemoved }) => {
    const [t] = useTranslation();
    const onRemoveClicked = useCallback(() => {
        onImageRemoved(setIndex, imageIndex);
    }, [setIndex, imageIndex, onImageRemoved]);
    return (
        <Root>
            <StyledImage src={url} alt={`Preview Set: ${setIndex}, image: ${imageIndex}`} />
            <PrimaryButton onClick={onRemoveClicked} type="button" disabled={false}>
                {t("components.fileInputPreview.remove")}
            </PrimaryButton>
        </Root>
    );
};
