import * as React from "react";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IFileInput } from "../../../../../atoms/FileUploader/FileUploader";
import { P } from "../../../../../atoms/P/P";
import { ImageRoot, List } from "./FileInputPreview.styles";
import { SelectedImage } from "./SelectedImage/SelectedImage";

interface IProps {
    readonly images: Array<Array<IFileInput>>;
    readonly setImages: (files: Array<Array<IFileInput>>) => void;
}

export const FileInputPreview: React.FC<IProps> = ({ images, setImages }) => {
    const [t] = useTranslation();
    const imageCount = images.reduce((acc, curr) => acc + curr.length, 0);
    const selectedTextLocale =
        imageCount === 1
            ? t("components.fileInputPreview.totalSelectedSingular", { imageCount })
            : t("components.fileInputPreview.totalSelectedPlural", { imageCount });

    const previewUrls = useMemo(() => {
        const urls = images.map((set) => set.map((fileInput) => URL.createObjectURL(fileInput.data)));

        return urls;
    }, [images]);

    useEffect(() => {
        // Clean up the object URLs when the component is unmounted
        return () => {
            previewUrls.forEach((urlSet) => urlSet.forEach((url) => URL.revokeObjectURL(url)));
        };
    }, [previewUrls]);

    const onImageRemoved = useCallback(
        (setIndex: number, imageIndex: number) => {
            const newImages = [...images];
            const currentSet = newImages[setIndex];
            currentSet.splice(imageIndex, 1);
            setImages(newImages);
        },
        [images, setImages]
    );

    const imagePreviews = previewUrls.map((urlSet, setIndex) => {
        const setImages = urlSet.map((url, imageIndex) => (
            <ImageRoot key={imageIndex}>
                <SelectedImage url={url} imageIndex={imageIndex} setIndex={setIndex} onImageRemoved={onImageRemoved} />
            </ImageRoot>
        ));
        return <List key={setIndex}>{setImages}</List>;
    });

    return (
        <div>
            <P>{selectedTextLocale}</P>
            {imagePreviews}
        </div>
    );
};
